import Head from 'next/head';
import { ReactNode } from 'react';
import styles from './index.module.scss';

interface DefaultLayoutProps {
  title: string,
  children: ReactNode,
  description: string,
  keywords: string
}

const DefaultLayout = ({
  children, title, description, keywords,
}: DefaultLayoutProps) => (
  <>
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
    </Head>
    <div className={styles.container}>
      {children}
    </div>
  </>
);

export default DefaultLayout;
