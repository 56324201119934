import {
  ChangeEvent, FormEvent, useState, useCallback, useContext,
} from 'react';
import Link from 'next/link';

import GoogleButton from 'components/common/GoogleButton';
import useUserUpdate from 'hooks/api/useUserUpdate';
import Button from 'components/core/Button';
import Input from 'components/core/Input';
import useAuth from 'hooks/api/useAuth';

import { useRouter } from 'next/router';
import { NoticeContext } from 'contexts/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss';

const Login = ({ domain }: { domain: string }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const { addUser } = useUserUpdate();
  const { query } = useRouter();
  const { message } = useContext(NoticeContext);
  const { t, i18n } = useTranslation('login');

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    login({ email, password }).then(({ data: { user: incomeUser } }) => {
      message('Success', 'success');
      addUser(incomeUser);
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
      message(e?.response?.statusText || e?.message, 'error');
    });
  }, [addUser, email, login, message, password]);

  return (
    <div className={styles.container}>
      <form className={styles.authForm} onSubmit={handleSubmit}>
        <span className={styles.welcome}>{t('title')}</span>
        <div className={styles.header}>
          <h1>{t('login')}</h1>
          <Link href='/'>
            <FontAwesomeIcon className={styles.home} icon={faHouse} />
          </Link>
        </div>
        <Input
          value={email}
          type='email'
          name='email'
          id='email'
          label={t('inp.email')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
        <Input
          value={password}
          type='password'
          name='password'
          id='password'
          label={t('inp.password')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
        <Button type='submit' title={t('submit')} loading={loading} disabled={loading} />
        <div className={styles.hr}><span>OR</span></div>
        <div className={styles.oauthWrapper}>
          <GoogleButton domain={domain} lang={i18n.language} />
        </div>
        <div id='Callout'>
          {t('needAccount')}
          <Link href={query.page ? `/register?page=${query.page as string}` : '/register'} passHref>
            {t('register')}
          </Link>
        </div>
        <div id='Callout'>
          <Link href='/recovery' passHref>{t('lostPassword')}</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
