import { ChangeEvent } from 'react';
import styles from './index.module.scss';

interface InputLayoutProps {
  type: string,
  name: string,
  id: string,
  label: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  value?: string;
}

const Input = ({
  type, name, id, label, onChange, value,
}: InputLayoutProps) => (
  <div className={styles.userBox}>
    <input type={type} name={name} id={id} onChange={onChange} required value={value} />
    <label htmlFor={id} className={value && styles.notEmpty}>{label}</label>
  </div>
);

Input.defaultProps = {
  value: '',
};

export default Input;
