import { GOOGLE_URL } from 'constants/index';
import { GoogleBtnIcon } from 'components/icons';
import styles from './index.module.scss';

const GoogleButton = ({ domain, lang }: { domain: string, lang: string }) => (
  <div className={styles.wrapper}>
    <a href={GOOGLE_URL.get(domain, lang)} className={styles.a}>
      <div className={styles.google}>
        <GoogleBtnIcon />
        <span className={styles.title}>Continue with Google</span>
      </div>
    </a>
  </div>
);

export default GoogleButton;
