import type { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import DefaultLayout from '../layouts/Default';
import Login from '../components/pages/Login';

const LoginPage: NextPage = ({ domain }: { domain: string }) => (
  <DefaultLayout
    title='Login | Fast Email Delivery'
    description='Log in for HotCardDelivery & get access to the USA eShop store with Fast Email Delivery.'
    keywords='Buy iTunes Gift Cards, iTunes Email Delivery, iTunes Gift Card Online, iTunes Fast Delivery, iTunes Online Delivery, iTunes Gift Certificate, Gift Card, Online Code'
  >
    <Login domain={domain} />
  </DefaultLayout>
);

export async function getServerSideProps({ req, locale }) {
  const www = req.headers.host.includes('www') ? 'www.' : '';
  const domain = req.headers.host.includes('localhost') ? `http://${req.headers.host}` : `https://${www}hotcarddelivery.com`;
  return {
    props: {
      domain,
      ...(await serverSideTranslations(locale, [
        'login',
      ])),
    },
  };
}

export default LoginPage;
